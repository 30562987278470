.cardWrap {
  height: 400px;
  width: 670px;
  background: #fff;
  border-radius: 10px;
  padding: 23px 23px 0px 23px;
  transform: translateX(350px);
  display: flex;
}

.cardImgMobile {
  object-fit: contain;
  margin-inline-end: 12px;
  height: 54px;
  width: 54px;
  filter: blur(5px);
}

.pWrap {
  width: 100%;
  display: flex;
  align-items: center;
}

.cardText {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #191919;
  padding-block-start: 5px;
  width: 80%;
}

.fakeBorderWrap {
  display: flex;
  justify-content: flex-end;
  width: 12%;
}

.fakeBorder {
  border-left: 1px solid #e5e7eb;
  height: auto;
}

.logo {
  height: 100px !important;
  width: 100px !important;
}

.leftSvg {
  border: 1px solid black;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 187px;
  bottom: 512px;
}

.leftSvg:hover {
  background-color: #111920;
}

.leftSvg:hover > svg {
  fill: white;
}

.rightSvg {
  border: 1px solid black;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 110px;
  bottom: 512px;
}

.rightSvg:hover {
  background-color: #111920;
}

.rightSvg:hover > svg {
  fill: white;
}

.secondSection {
  display: flex;
  justify-content: space-between;
  padding-inline: 25px;
  background: #f8f8f8;
  align-items: center;
  height: 156px;
  overflow-x: scroll;
  gap: 50px;
}

.secondSection img {
  height: 60px;
  width: 130px;
  object-fit: cover;
}
.mobileSecondSection {
  display: none;
}

.secondSection > img {
  object-fit: contain;
  max-width: 100%;
}

.mobileImg {
  height: 31px;
}

.firstSection {
  display: flex;
  padding-inline: 97px;
  width: 100%;
  padding-block: 93px 118px;
}

.firstSection > div:nth-child(1) {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.firstSectionButtonWrap {
  width: 50%;
  text-decoration: none;
}

.gridContainer {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.gridItem img {
  width: 100%;
}

.span2Wrap {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.blackboyWrap {
  width: 50%;
}

.blackboyWrap img {
  width: 100%;
}

.bannerHeader {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 110.19%;
  color: #17255a;
}

.fifthP {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #111920;
  margin-block: 24px;
}

.firstSection button {
  padding: 19.5px 46px;
  margin-block-start: 24px;
  display: flex;
  align-items: center;
}

.firstSection button svg {
  margin-inline-start: 22px;
}

.thirdSection {
  padding-inline-start: 119px;
  margin: 84px 0 194px 0;
  width: 100%;
  display: flex;
}

.mainContent {
  display: flex;
  max-width: 95%;
}

.stepwrapper {
  width: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 340px;
  justify-content: space-between;
  position: relative;
  padding-top: 5px;
}

.stepwrapper svg {
  position: relative;
  z-index: 10;
  cursor: pointer;
}

.stepwrapperBorder {
  border-left: 1px solid #e5e7eb;
  height: 400px;
  position: absolute;
  top: 0;
}

.mainContent div:nth-child(1) {
  width: 40%;
  margin-inline-end: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 431px;
}

.mainContent div:nth-child(2) {
  width: 45%;
}

.activeTextStyle {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-block-end: 32px;
  color: #191919;
}

.inactiveTextStyle {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-block-end: 32px;
  color: #a1a1a1;
}

.mainContent img {
  max-width: 100%;
}

.mobileWynWrap p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #191919;
}

.sectionThreeTitle {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 64px;
  color: #191919;
  padding-inline-start: 106px;
  margin-block-start: 84px;
  width: 50%;
}

.fourthSection {
  width: 100%;
  background-image: url("../../../assets/images/backgroundPattern.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ImgContainer {
  width: 50%;
}

.ImgContainer img {
  max-width: 100%;
}

.fourthSection > div:nth-child(1),
.fourthSection div:nth-child(3) {
  display: flex;
  align-items: flex-start;
  width: 65%;
  margin: auto;
  justify-content: space-between;
}

.fourthSection div:nth-child(3) {
  display: flex;
  align-items: flex-end;
  width: 65%;
  margin: auto;
  justify-content: space-between;
}

.textWrap {
  text-align: center;
}

.textWrap h6 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.textWrap p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  font-size: 40px;
}

.textWrap h5 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 119.759px;
  color: #ffffff;
}

.fifthSection {
  display: flex;
  margin: 37px 0 2px 0;
  justify-content: space-between;
  width: 100%;
}

.fifthSectionImgCard {
  width: 55%;
  display: flex;
}

.fifthSectionText {
  padding-inline-start: 110px;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fifthSection h2 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 64px;
  color: #191919;
}

.fifthButtonWrap {
  width: 55%;
}

.fifthSection button {
  padding: 8px 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
}

.sixSection {
  background: #f6fcf8;
  padding-block: 45px 56px;
  overflow: hidden;
}

.testimonialHeader {
  display: flex;
  justify-content: space-between;
  padding-inline: 103px;
}

.testimonialHeader h5 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 64px;
  width: 50%;
  color: #191919;
  padding-block-end: 47px;
}

.check {
  width: 10%;
}

.cardChild {
  width: 50%;
  margin-inline: 10px;
}

.mobileThirdSection {
  display: none;
}

.lastSection {
  width: 100%;
  height: 304px;
  background-image: url("../../../assets/images/backgroundImgCommunity.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-inline: 204px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lastSection div:nth-child(1) {
  width: 45%;
}

.lastSection h3 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 64px;
  color: #ffffff;
  margin-block-end: 18px;
}

.lsText {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.lastSection button {
  padding: 19.5px 116px;

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110.19%;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  color: #fefefe;
}

.disclaimer {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  padding-block-start: 10px;
  text-align: center;
}

.disclaimer span {
  text-decoration: underline;
}

.mobileFifthSectionImgCard {
  display: none;
}

@media screen and (max-width: 1280px) {
  .lastSection {
    padding-inline: 70px;
  }
}

@media screen and (max-width: 1024px) {
  .lastSection h3 {
    font-size: 35px;
  }

  .leftSvg {
    position: absolute;
    right: 500px;
    bottom: -11px;
  }

  .rightSvg {
    position: absolute;
    right: 430px;
    bottom: -11px;
  }

  .fifthSection {
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    margin: 34px 0;
  }

  .fifthSectionText {
    padding: 0 16px;
    margin-block-end: 74px;
    width: 100%;
  }

  .fifthSectionImgCard {
    width: 100%;
  }

  .firstSection {
    padding-inline: 50px;
    flex-wrap: wrap;
    padding-block: 105px;
  }

  .firstSection > div:nth-child(1) {
    width: 100%;
    padding-inline: 16px;
    padding-block-end: 40px;
  }

  .bannerHeader {
    font-size: 40px;
  }

  .gridContainer {
    width: 100%;
  }

  .span2Wrap {
    align-items: center;
  }

  .thirdSection,
  .mainContent {
    flex-wrap: wrap;
  }

  .mainContent div:nth-child(1) {
    width: 100%;
  }

  .mainContent div:nth-child(2) {
    width: 100%;
  }

  .mobileThirdSection {
    display: none;
  }

  .mobileStepwrapperBorder {
    border: 1px solid #ccc;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 29px;
    height: 97%;
  }

  .mobileText {
    display: flex;
  }

  .mobileText img {
    height: 29px;
    width: 29px;
    z-index: 0;
  }

  .sectionThreeTitle {
    font-size: 32px;
    line-height: 43px;
    width: 70%;
    padding-inline-start: 19px;
    margin: 47px 0 56px 0;
  }

  .textWrap h6 {
    font-size: 14px;
  }

  .textWrap p {
    font-size: 18px;
    line-height: 24px;
    margin-block: 13px;
  }

  .textWrap h5 {
    font-size: 56px;
    line-height: 83px;
  }

  .testimonialHeader {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .testimonialHeader h5 {
    width: 100%;
  }

  .fourthSection > div:nth-child(1),
  .fourthSection div:nth-child(3) {
    gap: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .leftSvg {
    position: absolute;
    right: 300px;
    bottom: 455px;
  }

  .rightSvg {
    position: absolute;
    right: 230px;
    bottom: 455px;
  }

  .span2Wrap {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .secondSection {
    display: none;
  }

  .mobileSecondSection {
    display: flex;
    height: 100px;
    align-items: center;
  }

  .mobileSecondSection img {
    height: 100px;
    object-fit: contain;
  }

  .thirdSection {
    display: none;
  }

  .mobileWynWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block-end: 64px;
  }

  .mobileWynWrap p {
    padding-block-end: 16px;
    margin-inline-start: 16px;
  }

  .mobileWynWrap img {
    max-width: 100%;
  }

  .lastSection {
    flex-wrap: wrap;
    padding-inline: 17px;
  }

  .lastSection div:nth-child(1),
  .lastSection div:nth-child(2) {
    width: 100%;
  }

  .lastSection button {
    padding-inline: 79px;
    font-size: 15px;
  }

  .lastSection h3 {
    font-size: 32px;
    line-height: 43px;
  }

  .disclaimer {
    text-align: start;
  }

  .mobileThirdSection {
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    padding-inline: 16px;
    position: relative;
  }
}

@media screen and (max-width: 425px) {
  .leftSvg {
    position: absolute;
    right: 156px;
    bottom: 345px;
  }

  .rightSvg {
    position: absolute;
    right: 216px;
    bottom: 345px;
  }

  .bannerHeader {
    font-size: 36px;
  }

  .testimonialHeader {
    padding-inline: 10px;
    text-align: center;
  }

  .testimonialHeader h5 {
    font-size: 32px;
    line-height: 47px;
    padding: 0 0 96px 0;
  }

  .gridContainer {
    width: 100%;
  }

  .firstSection {
    padding: 50px 15px;
  }

  .firstSectionButtonWrap {
    width: 100%;
    text-decoration: none;
  }

  .firstSectionButtonWrap button,
  .lastSection button {
    padding: 19.5px 30px;
  }

  .mobileWynWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block-end: 35px;
  }

  .mobileWynWrap p {
    padding-block-end: 16px;
  }

  .mobileImageWrap {
    width: 70%;
  }

  .lastSection {
    height: 482px;
  }

  .fifthSectionImgCard {
    display: none;
  }

  .fifthSection h2 {
    font-size: 32px;
    margin: 0;
  }

  .fifthP {
    margin-block: 16px;
  }

  .mobileFifthSectionImgCard {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: 93%;
    margin: auto;
  }

  .fourthSection {
    margin-block-start: 28px;
  }

  .cardImgMobile {
    height: 54px;
    width: 54px;
  }

  .pWrap {
    height: 50%;
  }

  .cardText {
    font-size: 12px;
  }

  .cardWrap {
    height: 324px;
    width: 390px;
    background: rgb(255, 255, 255);
    border-radius: 10px;
    transform: translateX(350px);
    display: flex;
  }
}

@media screen and (max-width: 375px) {
  .cardWrap {
    width: 350px;
  }

  .leftSvg {
    right: 142px;
  }

  .rightSvg {
    right: 205px;
  }
}
